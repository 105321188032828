import React, { useEffect } from 'react';
import { Grid, GridCell, Divider } from 'react-md';
import LineItemDetail from '../lineItemDetail/index';
import * as CTRL from '../buildControls/index';
// import { sectionFieldLogic } from '../triggers/index';
import { frameworkSetter } from '../../../hooks/usePageFramework';
import useWindowDimensions from '../../../hooks/useWindowDimensions.js';
import { handleFormColumns } from '../../../utils/handleFormColumns.js';
import LineItemSection from '../lineItemSection';
import LineItemFooter from '../lineItemFooter';
import GroupItemDetail from '../groupItemDetail';
import BondsItem from '../bondsItem';
import classNames from 'classnames';

const BuildFormGroups = (props) => {
    const { section,
        screenSize,
        saveForm,
        isFormLocked,
        triggered,
        sectionIndex,
        entityScrollTab,
        entityIndex,
        setUniqueEntityFieldValue,
        showSharedEntityDialog,
        parentGroup,
        parentIndex,
        isDenseRowNA,
        sectionFieldLogic,
        renderEntity,
        grandParentGroup,
        isEntryInterview,
        formSections,
        updateEntityUniqueValue,
        allSections
    } = props;
    const { selectState, REDUX, ACTION, dispatch, setCardsRequired } = frameworkSetter.usePageFramework();
    const setMaritalStatus = (maritalStatus) => dispatch(ACTION.setClientMaritalStatus(maritalStatus));
    const clientMaritalStatus = selectState(REDUX.CLIENT_MARITAL_STATUS);
    const { width } = useWindowDimensions();
    // eslint-disable-next-line no-unused-vars
    let { sectionClass } = props;

    const styles = (hasDivider, groupType) => {
        const title = 'Tell us';

        if (groupType === 'entityScrollTab') {
            return {}
        }

        if (section.title === title)
            return {
                marginTop: '-30px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between'
            };
        else if (section.title !== title && hasDivider)
            return {
                // marginTop: '-30px',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
            };
        else if (section.title !== title && !hasDivider)
            return {
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginTop: '-25px'
            };
        else return { marginTop: '-30px' };
    };

    const renderFooter = () => {
        let summaryGroup = section.groups.filter(group => group.groupType === 'groupItem');
        const visibleGroupItems = summaryGroup
            .map(group => group.groupItems?.map(item => item
                ?.filter(group => group.isVisible))
                .filter(items => items?.length > 0))
            .filter(items => items.length > 0);
        const visibleGroups = section.groups.filter(group => group.isVisible);

        return ((summaryGroup.length > 0 && visibleGroupItems.length > 0) || visibleGroups.length > 0) && section.footers &&
            <Grid key={`footer-grid-${section.sectionId}`} className='denseRowFooterLabel'>
                {
                    section.footers.map((item, index) => {
                        return <LineItemFooter key={index} group={item} item={item} index={index} />;
                    })
                }
            </Grid>;
    }

    const groups = !section.groups ? [] : section.groups.map((group, index) => {
        if (group.uniqueVisibilityName) {
            setUniqueEntityFieldValue({ group: group });
        };
        const isGrpVisible = typeof (group.isVisible) === 'undefined' && !group.uniqueVisibilityName ? true : group.isVisible;

        if (!isGrpVisible && !group.excuseInVisible) return (null);

        const grpLen = group.fields ? group.fields.length : 0;
        const isSingleColumn = group.isSingleColumn ? group.isSingleColumn : false;
        let colspan = 1;

        switch (screenSize) {
            case 'Phone':
                colspan = 4;
                break;
            case 'Tablet':
                colspan = grpLen >= 2 ? 4 : 8;
                break;
            default:
                colspan = grpLen > 6 ? 1 : grpLen === 6 ? 2 : grpLen === 5 ? 3 : grpLen === 4 ? 3 : grpLen === 3 ? 4 : 6;
                break;
        }

        if (group.groupType === 'lineItem' || group.groupType === 'lineSection') {
            sectionClass = group.groupType === 'lineSection' ? 'lineSection' : 'lineItemSection';

            if (section.logicFunction) {
                section.logicFunction.forEach((logic) => { sectionFieldLogic(logic?.trigger, { section, group }) });
            }

            if (group.logicFunction) {
                group.logicFunction.forEach((logic) => { sectionFieldLogic(logic?.trigger, { section, group, clientMaritalStatus }) });
            }

            return (
                <LineItemDetail
                    key={`lid-${section.sectionId}-${group.groupId}`}
                    group={group}
                    colspan={colspan}
                    screenSize={screenSize}
                    fns={{ saveForm, triggered, setUniqueEntityFieldValue, sectionFieldLogic }}
                    isFormLocked={isFormLocked}
                    section={section}
                    sectionIndex={sectionIndex}
                    groupIndex={index}
                    entityIndex={entityIndex}
                    showSharedEntityDialog={showSharedEntityDialog}
                    parentGroup={parentGroup}
                    parentIndex={parentIndex}
                    isDenseRowNA={isDenseRowNA}
                    grandParentGroup={grandParentGroup}
                    formSections={formSections}
                    allSections={allSections}
                    renderLineItemSections={(
                        lineItemIndex,
                        group,
                        fns,
                        sectionIndex,
                        groupIndex,
                        lineIndex,
                        setIsAddingNewEntity
                    ) => (
                        <LineItemSection
                            id={`lis-${lineItemIndex}`}
                            key={`lis-${lineItemIndex}`}
                            hasLineItemSection={true}
                            entities={group.entities[lineItemIndex]}
                            parentFns={fns}
                            parentGroup={group}
                            sectionIndex={sectionIndex}
                            groupIndex={groupIndex}
                            parentIndex={lineItemIndex}
                            renderEntity={renderEntity}
                            setIsAddingNewEntity={setIsAddingNewEntity}
                            isFormLocked={isFormLocked}
                            allSections={allSections}
                        />
                    )}
                />
            );
        }

        if (group.groupType === 'groupItem') {
            sectionClass = group.groupType === 'lineItemSection';

            if (section.logicFunction) {
                section.logicFunction.forEach((logic) => { sectionFieldLogic(logic?.trigger, { section, group }) });
            }

            if (group.logicFunction) {
                group.logicFunction.forEach((logic) => { sectionFieldLogic(logic?.trigger, { section, group, clientMaritalStatus }) });
            }

            return (
                <GroupItemDetail
                    key={`lid-${section.sectionId}-${group.groupId}`}
                    group={group}
                    colspan={colspan}
                    screenSize={screenSize}
                    fns={{ saveForm, triggered, setUniqueEntityFieldValue, sectionFieldLogic }}
                    isFormLocked={isFormLocked}
                    section={section}
                    sectionIndex={sectionIndex}
                    groupIndex={index}
                    entityIndex={entityIndex}
                    showSharedEntityDialog={showSharedEntityDialog}
                    parentGroup={parentGroup}
                    parentIndex={parentIndex}
                    grandParentGroup={grandParentGroup}
                    allSections={allSections}

                />
            );
        }

        if (group.groupType === 'bondsItem') {
            if (section.logicFunction) {
                section.logicFunction.forEach((logic) => { sectionFieldLogic(logic?.trigger, { section, group }) });
            }

            if (group.logicFunction) {
                group.logicFunction.forEach((logic) => { sectionFieldLogic(logic?.trigger, { section, group, clientMaritalStatus }) });
            }

            return (
                <BondsItem
                    key={`lid-${section.sectionId}-${group.groupId}`}
                    group={group}
                    colspan={colspan}
                    screenSize={screenSize}
                    fns={{ saveForm, triggered, setUniqueEntityFieldValue, sectionFieldLogic }}
                    isFormLocked={isFormLocked}
                    section={section}
                    sectionIndex={sectionIndex}
                    groupIndex={index}
                    entityIndex={entityIndex}
                    showSharedEntityDialog={showSharedEntityDialog}
                    parentGroup={parentGroup}
                    parentIndex={parentIndex}
                    grandParentGroup={grandParentGroup}
                    formSections={formSections}
                />
            )

        }

        const gt = group.groupType;
        const detailsFn = gt === 'details' ? CTRL.buildDetailsCtrl :
            gt === 'blueDivider' ? CTRL.buildBlueDivider :
                gt === 'groupDivider' ? CTRL.buildGroupDivider : null;

        if (detailsFn) {
            sectionClass = 'lineItemSection';
            return (<React.Fragment key={`line-item-section-${group.groupId}`}>{detailsFn(group, { saveForm, triggered, sectionFieldLogic })}</React.Fragment>);
        }

        const fields = group.fields.map((field, index) => {
            const isVisible = typeof (field.isVisible) === 'undefined' ? true : field.isVisible;
            if (!isVisible && !field.hasColSpace) return ('');

            if (field.uniqueName) setUniqueEntityFieldValue({ field: field });

            field.isSingleColumn = isSingleColumn;
            if (field.type === 'checkbox' || field.type === 'checkboxLabel') {
                field.isBlueBox = gt === 'blueBox';
            }

            if (!field.isRendered && field.onInit && typeof field.onInit === 'function') {
                field.onInit({ group, field, index, section, parentGroup, parentIndex })
            }

            if (!field.isPriorTriggered && field?.priorYearValue && field.logicFunction && !parentGroup?.isSummaryRow) {
                field.logicFunction.forEach((logic) => {
                    const fieldValue = field.default ? field.default : field.priorYearValue;
                    logic.isDifferentGroup ? triggered(logic.trigger, fieldValue, null) :
                        sectionFieldLogic(logic?.trigger, { group, field, index: index, logic, parentGroup, parentIndex, section, fns: { setMaritalStatus } })
                });
                field.isPriorTriggered = true;
                if (field.requiredCardsToSet) {
                    setCardsRequired(field.requiredCardsToSet, field.isSetRequiredCards);
                }
            }

            const col = handleFormColumns(field, width, field.col || colspan);

            const ctrl = CTRL.buildField;

            field.isRendered = true;

            return (
                <React.Fragment key={`field-wrapper-${group.groupId}-${field.name}`}>
                    <GridCell
                        colSpan={col}
                        key={`ctrl-${index}-${group.groupId}`}
                    >
                        {ctrl(field, col, index, group, isFormLocked, { saveForm, triggered, showSharedEntityDialog, setMaritalStatus, sectionFieldLogic, updateEntityUniqueValue }, section, parentGroup, parentIndex, grandParentGroup, allSections)}
                    </GridCell>
                    {field.hasDivider && <GridCell colSpan={12}><Divider /></GridCell>}
                </React.Fragment>
            );

        });

        if (gt === 'blueBox')
            return (CTRL.buildBlueCheckboxCtrl(fields, index));

        if (gt === 'dropdownGroup')
            return (CTRL.buildDropdownGroup(fields, index));

        const children = isSingleColumn ? (
            <React.Fragment key={`form-row-${section.sectionId}-${group.groupId}`}>
                <GridCell
                    colSpan={12}
                    key={`cell-${section.sectionId}-${group.groupId}`}
                    style={styles(group.hasDivider, gt)}
                >
                    {gt === 'entityScrollTab' && <div style={{ marginBottom: '45px' }}>{entityScrollTab}</div>}
                    {fields}
                </GridCell>
                {group.hasDivider && <GridCell colSpan={12}><Divider /></GridCell>}
            </React.Fragment>) :
            <React.Fragment key={`form-row-${section.sectionId}-${group.groupId}`}>
                {
                    group.title &&
                    <GridCell colSpan={12}>
                        <div
                            className='sectionHeaderContainer'
                        >
                            <div className='sectionHeaderTitle'>
                                <div className="sectionHeaderTitleContainer">
                                    <div>
                                        <p className='denseRowFormTitle'>
                                            {
                                                group.individualName ?
                                                    <>
                                                        {`${group.individualName} - `}
                                                        <span className='namedOrgTitle'>{group.title}</span>
                                                    </> : group.title
                                            }
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </GridCell>
                }
                {fields}
                {group.hasDivider && <GridCell colSpan={12}><Divider /></GridCell>}
            </React.Fragment>;

        return (
            <Grid
                key={`grid-${section.sectionId}-${group.groupId}-${index}`}
                className={
                    isEntryInterview ? '' :
                        group.class === 'defaultGroup' ? 'defaultGroup' :
                            group.class === 'deductionsGroup' ? 'deductionsGroup' :
                                group.class === 'denseRowCheckboxGroup' ? 'denseRowCheckboxGroup' :
                                    `formLineRowContainer ${gt === 'entityScrollTab' ? 'scrollTabGrid' :
                                        group.class ? group.class : ''}`
                }
            >
                {children}
            </Grid>
        );
    });

    return (
        <>
            {groups}
            {renderFooter()}
        </>
    );
};

export default BuildFormGroups;
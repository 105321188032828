import * as EVENT from '@utilities/constants/triggerKeys';
import * as REDUX from '@utilities/constants/redux';

const showOrgDbaName = (options) => {
  const { section, field } = options;

  section.groups[2].isVisible = field.default === EVENT.KEY_YES;
};

const showPrinAddress = (options) => {
  const { section, field } = options;

  section.groups[2].hasDivider = field.default === EVENT.KEY_YES;
  section.groups[3].isVisible = field.default === EVENT.KEY_NO;
  section.groups[4].isVisible = field.default === EVENT.KEY_NO;
  section.groups[5].isVisible = field.default === EVENT.KEY_NO;
  section.groups[6].isVisible = field.default === EVENT.KEY_NO;
};

const showForm990Signer = (event, options) => {
  const { sections, formSections, setFormSections } = options;
  const name = formSections ? formSections : sections;

  name[2].isVisible = event === EVENT.KEY_NO;

  setFormSections(name);
};

const showAddressFields = (options) => {
  const { section, field } = options;
  const isUnitedStates =
    field.default === EVENT.KEY_UNITED_STATES ||
    field.default.trim() === EVENT.KEY_EMPTY;
  const isCanada = field.default === EVENT.KEY_CANADA;
  const isOther = !isUnitedStates && !isCanada;

  section.groups[6].fields[3].isVisible = isOther; // orgProv
  section.groups[6].fields[4].isVisible = isCanada; // orgCanProv
  section.groups[6].fields[5].isVisible = isUnitedStates; // orgState
  section.groups[6].fields[1].isVisible = isUnitedStates; // orgZip
  section.groups[6].fields[6].isVisible = !isUnitedStates; // orgPostal

  section.groups[7].fields[0].isVisible = isUnitedStates; // orgPhoneUS
  section.groups[7].fields[1].isVisible = !isUnitedStates; // orgPhoneIntl
};

const showPrinAddCountry = (options) => {
  const { section, field } = options;
  const isUnitedStates =
    field.default === EVENT.KEY_UNITED_STATES ||
    field.default.trim() === EVENT.KEY_EMPTY;
  const isOther = !isUnitedStates;

  section.groups[6].fields[3].isVisible = isOther; // prinOffProv
  section.groups[6].fields[4].isVisible = isUnitedStates; // prinOffState
  section.groups[6].fields[1].isVisible = isUnitedStates; // prinOffZip
  section.groups[6].fields[5].isVisible = isOther; // prinOffPostal
};

const showTaxExemp = (options) => {
  const { section, field } = options;
  const isHospitalCharity =
    section.groups[12].fields[1].default === EVENT.KEY_HOSP_CHARITY;
  const is4947A1 = field.default === EVENT.EVENT_KEY_TAX_EXMP_2;

  if (section.groups && section.groups[12]) {
    section.groups[12].isVisible =
      field.default === EVENT.EVENT_KEY_TAX_EXMP_1 ||
      field.default === EVENT.EVENT_KEY_TAX_EXMP_2;
    if (is4947A1) {
      section.groups[12].fields[1].default =
        '170(b)(1)(A)(vi)-Gov/Public Funded';
      section.groups[12].fields[1].isDisable = true;
    } else {
      section.groups[12].fields[1].isDisable = false;
    }
  }
  if (section.groups && section.groups[13]) {
    section.groups[13].isVisible =
      isHospitalCharity &&
      (field.default === EVENT.EVENT_KEY_TAX_EXMP_1 ||
        field.default === EVENT.EVENT_KEY_TAX_EXMP_2);
  }
};

const showHosDbtClaPrep = (options) => {
  const { section, field } = options;

  if (section.groups && section.groups[13])
    section.groups[13].isVisible = field.default === EVENT.KEY_HOSP_CHARITY;
};

const showSubordinatesTable = (options) => {
  const { section, field } = options;

  if (section.groups && section.groups[8])
    section.groups[8].isVisible = field.default === EVENT.KEY_YES;

  if (section.groups && section.groups[9])
    section.groups[9].isVisible = field.default === EVENT.KEY_NO;

  if (section.groups && section.groups[10])
    section.groups[10].isVisible = field.default === EVENT.KEY_NO;
};

const showSubsProvState = (options) => {
  const { section, field } = options;
  const isUnitedStates =
    field.default === EVENT.KEY_UNITED_STATES ||
    field.default.trim() === EVENT.KEY_EMPTY;
  const isCanada = field.default === EVENT.KEY_CANADA;
  const isOther = !isUnitedStates && !isCanada;

  section.groups[0].fields[3].isVisible = isUnitedStates;
  section.groups[0].fields[4].isVisible = isOther;
  section.groups[0].fields[5].isVisible = isCanada;
};

const showContributionsFormFields = (options, fieldName) => {
  const { field, logic, section } = options;
  const contributionsForm = logic.triggerRelatedForms?.contributions;
  const basicDataTaxExempt =
    fieldName === 'taxExmptStat'
      ? field.default
      : section.groups[11].fields[1].default;
  const basicDataCharityStat =
    fieldName === 'pubCharStat'
      ? field.default
      : section.groups[12].fields[1].default;
  const isTaxExempt =
    (basicDataCharityStat === EVENT.KEY_PUBLIC_FUNDED ||
      basicDataCharityStat === EVENT.KEY_COMMUNITY_TRUST) &&
    (basicDataTaxExempt === EVENT.EVENT_KEY_TAX_EXMP_1 ||
      basicDataTaxExempt === EVENT.EVENT_KEY_TAX_EXMP_2);

  if (contributionsForm && contributionsForm[0].isVisible) {
    const contribRecTotal =
      contributionsForm[0].groups[0].fields[1].value ||
      contributionsForm[0].groups[0].fields[1].default;
    const contribSecRule1Calc = contribRecTotal * 0.02;
    const dollerUSlocale = Intl.NumberFormat('en-US');
    const contribSecRule1CalcDollar = `$${dollerUSlocale.format(
      contribSecRule1Calc.toFixed()
    )}`;

    contributionsForm[0].groups[5].isVisible =
      contribRecTotal >= 5000 && !isTaxExempt;
    contributionsForm[0].groups[3].isVisible =
      contribRecTotal >= 5000 && contribRecTotal <= 250000 && isTaxExempt;
    contributionsForm[0].groups[2].isVisible =
      contribRecTotal > 250000 && isTaxExempt;
    contributionsForm[0].groups[4].isVisible =
      contribRecTotal > 250000 && isTaxExempt;
    contributionsForm[0].groups[2].fields[1].label = contribSecRule1CalcDollar;
    contributionsForm[0].groups[4].fields[0].label = `Did the organization record contribution revenue of at least ${contribSecRule1CalcDollar} from any single contributor/grantor (includes both entities and individuals and both cash & noncash donations)?`;
    contributionsForm[0].groups[2].fields[1].default =
      contribSecRule1CalcDollar;
    contributionsForm[0].groups[4].fields[0].default = `Did the organization record contribution revenue of at least ${contribSecRule1CalcDollar} from any single contributor/grantor (includes both entities and individuals and both cash & noncash donations)?`;
    logic.setGlobalFormState(REDUX.CONTRIBUTIONS, contributionsForm);
  }
};

const setTaxExmptRequiredCards = (options, fieldName) => {
  const { field, section, logic } = options;
  const basicDataTaxExempt =
    fieldName === 'taxExmptStat'
      ? field.default
      : section.groups[11].fields[1].default;
  const basicDataCharityStat =
    fieldName === 'pubCharStat'
      ? field.default
      : section.groups[12].fields[1].default;

  //For Public Charity Sections visibility
  const publicCharityForm = logic.triggerRelatedForms?.publicCharity;
  const isTypeI = basicDataCharityStat === EVENT.KEY_509A3I;
  const isTypeII = basicDataCharityStat === EVENT.KEY_509A3II;
  const isTypeIIIFunc = basicDataCharityStat === EVENT.KEY_509A3III_FUNC;
  const isTypeIIINonFunc = basicDataCharityStat === EVENT.KEY_509A3III_NON_FUNC;
  const is509A2 = basicDataCharityStat === EVENT.KEY_509A2_GROSS_RECEIPTS_TEST;
  const isMedResearch = basicDataCharityStat === EVENT.KEY_MED_RESEARCH_ORG;
  const isAgriResearch = basicDataCharityStat === EVENT.KEY_AGRI_RESEARCH_ORG;
  const isCollUniv = basicDataCharityStat === EVENT.KEY_COLL_UNIV;
  const isAgCollUniv = isAgriResearch || isCollUniv;

  const isPubCharRequired =
    (basicDataTaxExempt === EVENT.EVENT_KEY_TAX_EXMP_1 ||
      basicDataTaxExempt === EVENT.EVENT_KEY_TAX_EXMP_2) &&
    (isTypeI ||
      isTypeII ||
      isTypeIIIFunc ||
      isTypeIIINonFunc ||
      is509A2 ||
      isMedResearch ||
      isAgriResearch ||
      isCollUniv);
  const isSchoolsRequired =
    basicDataCharityStat === EVENT.KEY_SCHOOL &&
    basicDataTaxExempt === EVENT.EVENT_KEY_TAX_EXMP_1;
  const isHospitalRequired =
    basicDataCharityStat === EVENT.KEY_HOSPITAL &&
    basicDataTaxExempt === EVENT.EVENT_KEY_TAX_EXMP_1;
  const isInterestedPersonsTransactions =
    basicDataTaxExempt === EVENT.EVENT_KEY_TAX_EXMP_1 &&
    (isTypeI || isTypeII || isTypeIIIFunc || isTypeIIINonFunc);
  let requiredCards = [
    { card: REDUX.SCHOOLS, isSetRequired: false },
    { card: REDUX.PUBLIC_CHARITY_AND_SUPPORT, isSetRequired: false },
    { card: REDUX.HOSPITAL, isSetRequired: false },
    { card: REDUX.INTERESTED_PERSONS_TRANSACTIONS, isSetRequired: false },
  ];

  if (isSchoolsRequired) requiredCards[0].isSetRequired = true;
  if (isPubCharRequired) requiredCards[1].isSetRequired = true;
  if (isHospitalRequired) requiredCards[2].isSetRequired = true;

  if (publicCharityForm) {
    const ispubChrSupOrgNonUSGrntCtrlYes =
      publicCharityForm[2].groups[14].isVisible &&
      publicCharityForm[2].groups[14].fields[1].value === EVENT.KEY_YES;
    const ispubChrSupOrgNonUSYes =
      publicCharityForm[2].groups[13].fields[1].value === EVENT.KEY_YES;
    const ispubChrSupOrgNonUSnoIRSYes =
      publicCharityForm[2].groups[16].fields[1].value === EVENT.KEY_YES;
    const ispubChrSupOrgExcsBusHoldYes =
      publicCharityForm[2].groups[35].fields[1].value === EVENT.KEY_YES;
    const ispubChrSupOrgPwrApptNo =
      publicCharityForm[3].groups[0].fields[1].value === EVENT.KEY_NO;
    const ispubChrSupOrgOpBenYes =
      publicCharityForm[3].groups[2].fields[1].value === EVENT.KEY_YES;
    const ispubChrSupOrgMjrBothNo =
      publicCharityForm[3].groups[4].fields[1].value === EVENT.KEY_NO;
    const isPubChrSupOrgWtrnNtc =
      publicCharityForm[3].groups[7].fields[1].value === EVENT.KEY_NO;
    const isPubChrSupOrgCopy990 =
      publicCharityForm[3].groups[8].fields[1].value === EVENT.KEY_NO;
    const isPubChrSupOrgCopyGovDoc =
      publicCharityForm[3].groups[9].fields[1].value === EVENT.KEY_NO;
    const ispubChrSupOrgOdtSrvGovBdyNo =
      publicCharityForm[3].groups[11].fields[1].value === EVENT.KEY_NO;
    const ispubChrSupOrgSignfVoiceYes =
      publicCharityForm[3].groups[13].fields[1].value === EVENT.KEY_YES;
    const ispubChrSupOrgActTest =
      publicCharityForm[3].groups[16].fields[1].value === true;
    const isPubChrSupOrgSubstExPurp =
      publicCharityForm[3].groups[17].fields[1].value === EVENT.KEY_YES;
    const isPubChrSupOrgConstInv =
      publicCharityForm[3].groups[19].fields[1].value === EVENT.KEY_YES;
    const ispubChrSupOrgParent =
      publicCharityForm[3].groups[21].fields[1].value === true;
    const isPubChrSupOrgPwrAptODT =
      publicCharityForm[3].groups[22].fields[1].value === EVENT.KEY_YES;
    const isPubChrSupOrgDirPlcyPrgrm =
      publicCharityForm[3].groups[24].fields[1].value === EVENT.KEY_YES;
    const ispubChrSupOrgGovEnt =
      publicCharityForm[3].groups[26].fields[1].value === true;
    const ispubChrSupOrgGrnt =
      publicCharityForm[2].groups[28].fields[1].value === EVENT.KEY_YES;
    if (isInterestedPersonsTransactions && ispubChrSupOrgGrnt) {
      requiredCards[3].isSetRequired = true;
    }

    //research org section
    publicCharityForm[0].isVisible =
      isPubCharRequired && (isMedResearch || isAgCollUniv);
    publicCharityForm[0].groups[0].isVisible =
      isPubCharRequired && isMedResearch;
    publicCharityForm[0].groups[1].isVisible =
      isPubCharRequired && isMedResearch;
    publicCharityForm[0].groups[2].isVisible =
      isPubCharRequired && isAgriResearch;
    publicCharityForm[0].groups[3].isVisible = isPubCharRequired && isCollUniv;
    publicCharityForm[0].groups[4].isVisible =
      isPubCharRequired && isAgCollUniv;
    publicCharityForm[0].groups[5].isVisible =
      isPubCharRequired && isAgCollUniv;

    //org support section
    publicCharityForm[1].isVisible = isPubCharRequired && is509A2;

    //supporting org section
    publicCharityForm[2].isVisible =
      isPubCharRequired &&
      (isTypeI || isTypeII || isTypeIIIFunc || isTypeIIINonFunc);
    publicCharityForm[2].groups[15].isVisible =
      isPubCharRequired &&
      (isTypeI || isTypeII) &&
      ispubChrSupOrgNonUSGrntCtrlYes;
    publicCharityForm[2].groups[16].isVisible =
      isPubCharRequired && (isTypeI || isTypeII) && ispubChrSupOrgNonUSYes;
    publicCharityForm[2].groups[17].isVisible =
      isPubCharRequired &&
      (isTypeI || isTypeII) &&
      ispubChrSupOrgNonUSYes &&
      ispubChrSupOrgNonUSnoIRSYes;
    publicCharityForm[2].groups[35].isVisible =
      isPubCharRequired && (isTypeII || isTypeIIINonFunc);
    publicCharityForm[2].groups[35].fields[2].isVisible =
      isPubCharRequired &&
      (isTypeII || isTypeIIINonFunc) &&
      ispubChrSupOrgExcsBusHoldYes;

    //supporting org type section
    publicCharityForm[3].isVisible =
      isPubCharRequired &&
      (isTypeI || isTypeII || isTypeIIIFunc || isTypeIIINonFunc);
    publicCharityForm[3].groups[0].isVisible = isPubCharRequired && isTypeI;
    publicCharityForm[3].groups[1].isVisible =
      isPubCharRequired && isTypeI && ispubChrSupOrgPwrApptNo;
    publicCharityForm[3].groups[2].isVisible = isPubCharRequired && isTypeI;
    publicCharityForm[3].groups[3].isVisible =
      isPubCharRequired && isTypeI && ispubChrSupOrgOpBenYes;
    publicCharityForm[3].groups[4].isVisible = isPubCharRequired && isTypeII;
    publicCharityForm[3].groups[5].isVisible =
      isPubCharRequired && isTypeII && ispubChrSupOrgMjrBothNo;
    publicCharityForm[3].groups[6].isVisible =
      isPubCharRequired && (isTypeIIIFunc || isTypeIIINonFunc);
    publicCharityForm[3].groups[7].isVisible =
      isPubCharRequired && (isTypeIIIFunc || isTypeIIINonFunc);
    publicCharityForm[3].groups[8].isVisible =
      isPubCharRequired && (isTypeIIIFunc || isTypeIIINonFunc);
    publicCharityForm[3].groups[9].isVisible =
      isPubCharRequired && (isTypeIIIFunc || isTypeIIINonFunc);
    publicCharityForm[3].groups[10].isVisible =
      isPubCharRequired &&
      (isTypeIIIFunc || isTypeIIINonFunc) &&
      (isPubChrSupOrgWtrnNtc ||
        isPubChrSupOrgCopy990 ||
        isPubChrSupOrgCopyGovDoc);
    publicCharityForm[3].groups[11].isVisible =
      isPubCharRequired && (isTypeIIIFunc || isTypeIIINonFunc);
    publicCharityForm[3].groups[12].isVisible =
      isPubCharRequired &&
      (isTypeIIIFunc || isTypeIIINonFunc) &&
      ispubChrSupOrgOdtSrvGovBdyNo;
    publicCharityForm[3].groups[13].isVisible =
      isPubCharRequired && (isTypeIIIFunc || isTypeIIINonFunc);
    publicCharityForm[3].groups[14].isVisible =
      isPubCharRequired &&
      (isTypeIIIFunc || isTypeIIINonFunc) &&
      ispubChrSupOrgSignfVoiceYes;
    publicCharityForm[3].groups[15].isVisible =
      isPubCharRequired && isTypeIIIFunc;
    publicCharityForm[3].groups[16].isVisible =
      isPubCharRequired && isTypeIIIFunc;
    publicCharityForm[3].groups[17].isVisible =
      isPubCharRequired && isTypeIIIFunc && ispubChrSupOrgActTest;
    publicCharityForm[3].groups[18].isVisible =
      isPubCharRequired &&
      isTypeIIIFunc &&
      ispubChrSupOrgActTest &&
      isPubChrSupOrgSubstExPurp;
    publicCharityForm[3].groups[19].isVisible =
      isPubCharRequired && isTypeIIIFunc && ispubChrSupOrgActTest;
    publicCharityForm[3].groups[20].isVisible =
      isPubCharRequired &&
      isTypeIIIFunc &&
      ispubChrSupOrgActTest &&
      isPubChrSupOrgConstInv;
    publicCharityForm[3].groups[21].isVisible =
      isPubCharRequired && isTypeIIIFunc;
    publicCharityForm[3].groups[22].isVisible =
      isPubCharRequired && isTypeIIIFunc && ispubChrSupOrgParent;
    publicCharityForm[3].groups[23].isVisible =
      isPubCharRequired &&
      isTypeIIIFunc &&
      ispubChrSupOrgParent &&
      isPubChrSupOrgPwrAptODT;
    publicCharityForm[3].groups[24].isVisible =
      isPubCharRequired && isTypeIIIFunc && ispubChrSupOrgParent;
    publicCharityForm[3].groups[25].isVisible =
      isPubCharRequired &&
      isTypeIIIFunc &&
      ispubChrSupOrgParent &&
      isPubChrSupOrgDirPlcyPrgrm;
    publicCharityForm[3].groups[26].isVisible =
      isPubCharRequired && isTypeIIIFunc;
    publicCharityForm[3].groups[27].isVisible =
      isPubCharRequired && isTypeIIIFunc && ispubChrSupOrgGovEnt;
    publicCharityForm[3].groups[28].isVisible =
      isPubCharRequired && isTypeIIIFunc;
    publicCharityForm[3].groups[29].isVisible =
      isPubCharRequired && isTypeIIIFunc;
    publicCharityForm[3].groups[30].isVisible =
      isPubCharRequired && isTypeIIIFunc;

    // Show additional Info section whenever other section/sections are visible
    if (publicCharityForm.length === 5) {
      publicCharityForm[4].isVisible =
        publicCharityForm[0].isVisible ||
        publicCharityForm[1].isVisible ||
        publicCharityForm[2].isVisible ||
        publicCharityForm[3].isVisible;
    }

    logic.setGlobalFormState(
      REDUX.PUBLIC_CHARITY_AND_SUPPORT,
      publicCharityForm
    );
  }
  field.requiredCardsToSet = requiredCards;
};

const showtTaxExmptStatTaxComplianceFields = (options) => {
  const { field, logic, section } = options;
  const taxComplianceForm = logic.triggerRelatedForms?.taxCompliance;
  const is4947A1 = field.default === EVENT.EVENT_KEY_TAX_EXMP_2;
  const isNot501C3 = field.default !== EVENT.EVENT_KEY_TAX_EXMP_1;
  const is501C12 = field.default === EVENT.KEY_501C12;
  const is501C7 = field.default === EVENT.KEY_501C7;
  const is501C29 = field.default === EVENT.KEY_501C29;
  const is501C3 = field.default === EVENT.EVENT_KEY_TAX_EXMP_1;
  const isSchool = section.groups[12].fields[1].default === EVENT.KEY_SCHOOL;

  if (taxComplianceForm) {
    const isothFilingsGrossRct100KYes =
      taxComplianceForm[0].groups[7].fields[1].value === EVENT.KEY_YES;
    const isothFilingsLicensedMultiStateYes =
      taxComplianceForm[2].groups[6].fields[1].value === EVENT.KEY_YES;
    const isothFilingsLicensedMultiStateNo =
      taxComplianceForm[2].groups[6].fields[1].value === EVENT.KEY_NO;
    const isothFilingsPartContGoodsYes =
      taxComplianceForm[1].groups[0].fields[1].value === EVENT.KEY_YES;

    taxComplianceForm[2].groups[4].isVisible = is4947A1;
    taxComplianceForm[2].groups[5].isVisible = is4947A1;

    taxComplianceForm[0].groups[7].isVisible = isNot501C3;
    taxComplianceForm[0].groups[8].isVisible =
      isNot501C3 && isothFilingsGrossRct100KYes;

    taxComplianceForm[2].groups[2].isVisible = is501C12;
    taxComplianceForm[2].groups[3].isVisible = is501C12;

    taxComplianceForm[2].groups[0].isVisible = is501C7;
    taxComplianceForm[2].groups[1].isVisible = is501C7;

    taxComplianceForm[2].groups[6].isVisible = is501C29;
    taxComplianceForm[2].groups[7].isVisible =
      is501C29 && isothFilingsLicensedMultiStateNo;
    taxComplianceForm[2].groups[8].isVisible =
      is501C29 && isothFilingsLicensedMultiStateNo;
    taxComplianceForm[2].groups[9].isVisible =
      is501C29 && isothFilingsLicensedMultiStateYes;
    taxComplianceForm[2].groups[10].isVisible =
      is501C29 && isothFilingsLicensedMultiStateYes;
    taxComplianceForm[2].groups[18].isVisible = is501C3 && isSchool;

    taxComplianceForm[1].groups[0].isVisible = is501C3;
    taxComplianceForm[1].groups[1].isVisible =
      is501C3 && isothFilingsPartContGoodsYes;

    logic.setGlobalFormState(REDUX.TAX_COMPLIANCE_FILINGS, taxComplianceForm);
  }
};

const showInterestedPersonsTransactionFields = (options) => {
  const { field, logic } = options;
  const interestedPersonsForm = logic.triggerRelatedForms?.interestedPersons;
  const is501C3 = field.default === EVENT.EVENT_KEY_TAX_EXMP_1;
  const is501C4 = field.default === EVENT.KEY_501C4;
  const is501C29 = field.default === EVENT.KEY_501C29;

  if (interestedPersonsForm) {
    const value = is501C3 || is501C4 || is501C29;
    interestedPersonsForm[0].groups[0].isVisible = value;
    interestedPersonsForm[0].groups[1].isVisible = value;

    const questions =
      interestedPersonsForm[0].groups[0].fields[1].value === EVENT.KEY_YES ||
      interestedPersonsForm[0].groups[1].fields[1].value === EVENT.KEY_YES;

    interestedPersonsForm[1].isVisible = value && questions;

    logic.setGlobalFormState(
      REDUX.INTERESTED_PERSONS_TRANSACTIONS,
      interestedPersonsForm
    );
  }
};

const showExcessBenefitTransactions = (options) => {
  const { field, logic } = options;
  const interestedPersonsForm = logic.triggerRelatedForms?.interestedPersons;
  const isTypeI = field.default === EVENT.KEY_509A3I;
  const isTypeII = field.default === EVENT.KEY_509A3II;
  const isTypeIIIFunc = field.default === EVENT.KEY_509A3III_FUNC;
  const isTypeIIINonFunc = field.default === EVENT.KEY_509A3III_NON_FUNC;

  if (interestedPersonsForm) {
    const value = isTypeI || isTypeII || isTypeIIIFunc || isTypeIIINonFunc;

    interestedPersonsForm[1].isVisible = value;

    logic.setGlobalFormState(
      REDUX.INTERESTED_PERSONS_TRANSACTIONS,
      interestedPersonsForm
    );
  }
};

const showPoliticalCampaignFields = (options) => {
  const { field, logic } = options;
  const politicalCampaignForm =
    logic.triggerRelatedForms?.politicalCampaignAndLobbying;
  const is501C3 = field.default === EVENT.EVENT_KEY_TAX_EXMP_1;
  const is501C4 = field.default === EVENT.KEY_501C4;
  const is501C5 = field.default === EVENT.KEY_501C5;
  const is501C6 = field.default === EVENT.KEY_501C6;
  if (politicalCampaignForm) {
    // const sec0Group1Trig = is501C3 || is501C4 || is501C5 || is501C6

    politicalCampaignForm[0].groups[1].isVisible = is501C3;
    politicalCampaignForm[0].groups[2].isVisible =
      is501C4 || is501C5 || is501C6;

    if (!is501C3) {
      politicalCampaignForm[0].groups[1].fields[1].value = null;
      politicalCampaignForm[1].groups[10].isVisible = true;
      politicalCampaignForm[1].groups[11].isVisible = true;
      politicalCampaignForm[1].groups[12].isVisible = true;
      politicalCampaignForm[1].groups[14].isVisible = true;
      politicalCampaignForm[1].groups[15].isVisible = true;
    } else {
      politicalCampaignForm[1].groups[10].isVisible = false;
      politicalCampaignForm[1].groups[11].isVisible = false;
      politicalCampaignForm[1].groups[12].isVisible = false;
      politicalCampaignForm[1].groups[14].isVisible = false;
      politicalCampaignForm[1].groups[15].isVisible = false;
    }

    if (is501C4 || is501C5 || is501C6) {
      politicalCampaignForm[2].groups[15].isVisible = true;
      politicalCampaignForm[2].groups[16].isVisible = true;
      politicalCampaignForm[2].groups[17].isVisible = true;
    } else {
      politicalCampaignForm[2].groups[15].isVisible = false;
      politicalCampaignForm[2].groups[15].fields[1].value = null;

      politicalCampaignForm[2].groups[16].isVisible = false;
      politicalCampaignForm[2].groups[16].fields[1].value = null;

      politicalCampaignForm[2].groups[17].isVisible = false;
      politicalCampaignForm[2].groups[17].fields[1].value = null;

      politicalCampaignForm[2].groups[18].isVisible = false;
      politicalCampaignForm[2].groups[19].isVisible = false;
      politicalCampaignForm[2].groups[20].isVisible = false;
      politicalCampaignForm[2].groups[21].isVisible = false;
      politicalCampaignForm[2].groups[22].isVisible = false;
      politicalCampaignForm[2].groups[19].hasDivider = false;
      politicalCampaignForm[2].groups[20].hasDivider = false;
      politicalCampaignForm[2].groups[21].hasDivider = false;
    }

    politicalCampaignForm[1].groups[13].isVisible =
      !is501C3 &&
      politicalCampaignForm[1].groups[12].fields[1].value === EVENT.KEY_NO;

    const reqSchdChkCii =
      is501C3 &&
      politicalCampaignForm[0].groups[1].fields[1].value === EVENT.KEY_YES;
    const reqSchdChkCi =
      (is501C3 &&
        politicalCampaignForm[0].groups[0].fields[1].value === EVENT.KEY_YES) ||
      (!is501C3 &&
        politicalCampaignForm[0].groups[0].fields[1].value === EVENT.KEY_YES);
    const reqSchdChkCiii =
      (is501C4 || is501C5 || is501C6) &&
      politicalCampaignForm[0].groups[2].fields[1].value === EVENT.KEY_YES;

    politicalCampaignForm[1].isVisible = reqSchdChkCii || reqSchdChkCi;
    politicalCampaignForm[2].isVisible = reqSchdChkCii || reqSchdChkCiii;

    logic.setGlobalFormState(
      REDUX.POLITICAL_CAMPAIGN_AND_LOBBYING,
      politicalCampaignForm
    );
  }
};

const setCardRequired = (options) => {
  const { field, logic } = options;
  const is501C3 = field.default !== EVENT.EVENT_KEY_TAX_EXMP_1;

  if (is501C3) {
    field.requiredCardsToSet = logic.requiredCards;
    field.isSetRequiredCards = true;
  } else {
    field.requiredCardsToSet = logic.requiredCards;
    field.isSetRequiredCards = false;
  }
};

const showPubCharStatTaxComplianceFields = (options) => {
  const { field, logic, section } = options;
  const taxComplianceForm = logic.triggerRelatedForms?.taxCompliance;
  const isSchool = field.default === EVENT.KEY_SCHOOL;
  const is501C3 =
    section.groups[11].fields[1].default === EVENT.EVENT_KEY_TAX_EXMP_1;

  if (taxComplianceForm) {
    taxComplianceForm[2].groups[18].isVisible = isSchool && is501C3;

    logic.setGlobalFormState(REDUX.TAX_COMPLIANCE_FILINGS, taxComplianceForm);
  }
};

const showOthFilingsLicensedMultiStateField = (options) => {
  const { field, logic } = options;
  const taxComplianceForm = logic.triggerRelatedForms?.taxCompliance;
  const is501C29 = field.default === EVENT.KEY_501C29;

  if (taxComplianceForm) {
    taxComplianceForm[2].groups[6].isVisible = is501C29;
    logic.setGlobalFormState(REDUX.TAX_COMPLIANCE_FILINGS, taxComplianceForm);
  }
};

const showFinancialExpenseSchedule = (options) => {
  const { field, logic } = options;
  const internalSetupForm = logic.triggerRelatedForms?.internalSetup;
  const financialsForm = logic.triggerRelatedForms?.financials;
  const is501C34 = [EVENT.KEY_501C4, EVENT.EVENT_KEY_TAX_EXMP_1].includes(field.default);

  if (financialsForm && internalSetupForm) {
    const isServiceProvided  = internalSetupForm[0].groups[12].fields[1].value;
    const isServiceProvidedNone = [EVENT.KEY_NONE_OF_THE_ABOVE, EVENT.KEY_NONE].includes(isServiceProvided)
    financialsForm[1].groups[1].isVisible = is501C34 && isServiceProvidedNone
    logic.setGlobalFormState(REDUX.FINANCIALS, financialsForm);
  }
};

const showBasicDatasubsIncl = (options) => {
  const { field, logic, group } = options;
  const internalSetupForm = logic.triggerRelatedForms?.internalSetup;

  if (internalSetupForm) {
    group.isVisible = internalSetupForm[0].groups[1].fields[1].value;
  }
};

const sectionFieldLogic = (logicFunction, options) => {
  switch (logicFunction) {
    case 'showOrgDbaName':
      showOrgDbaName(options);
      break;
    case 'showPrinAddress':
      showPrinAddress(options);
      break;
    case 'showAddressFields':
      showAddressFields(options);
      break;
    case 'showPrinAddCountry':
      showPrinAddCountry(options);
      break;
    case 'showTaxExemp':
      showTaxExemp(options);
      break;
    case 'showHosDbtClaPrep':
      showHosDbtClaPrep(options);
      break;
    case 'showSubordinatesTable':
      showSubordinatesTable(options);
      break;
    case 'showSubsProvState':
      showSubsProvState(options);
      break;
    case 'showtTaxExmptStatContributionFields':
      showContributionsFormFields(options, 'taxExmptStat');
      break;
    case 'showPubCharStatContributionsFields':
      showContributionsFormFields(options, 'pubCharStat');
      break;
    case 'setTaxExmptRequiredCardsTE':
      setTaxExmptRequiredCards(options, 'taxExmptStat');
      break;
    case 'setTaxExmptRequiredCardsPC':
      setTaxExmptRequiredCards(options, 'pubCharStat');
      break;
    case 'showtTaxExmptStatTaxComplianceFields':
      showtTaxExmptStatTaxComplianceFields(options);
      break;
    case 'showInterestedPersonsTransactionFields':
      showInterestedPersonsTransactionFields(options);
      break;
    case 'showPoliticalCampaignFields':
      showPoliticalCampaignFields(options);
      break;
    case 'setCardRequired':
      setCardRequired(options);
      break;
    case 'showOthFilingsLicensedMultiStateField':
      showOthFilingsLicensedMultiStateField(options);
      break;
    case 'showPubCharStatTaxComplianceFields':
      showPubCharStatTaxComplianceFields(options);
      break;
    case 'showExcessBenefitTransactions':
      showExcessBenefitTransactions(options);
      break;
    case 'showBasicDatasubsIncl':
      showBasicDatasubsIncl(options);
      break;
    case 'showFinancialExpenseSchedule':
      showFinancialExpenseSchedule(options);
      break;
    default:
      break;
  }
};

const triggeredEvent = (trigger, event, options) => {
  switch (trigger) {
    case 'showForm990Signer':
      showForm990Signer(event, options);
      break;
    default:
      break;
  }
};

export { triggeredEvent, sectionFieldLogic };
